import React from "react";
import * as S from "./Hero.styles";

const Hero = ({
  showRectangle = false,
  showMouse = false,
  subtitle,
  children,
  smaller,
}) => {
  return (
    <S.Hero smaller={smaller}>
      <h1>
        {children}
        {showRectangle && (
          <S.PinkRectangle src={require("../../images/pink-rectangle.svg")} />
        )}
      </h1>
      {subtitle && <p>{subtitle}</p>}

      {showMouse && <S.Mouse src={require("../../images/mouse.svg")} />}
    </S.Hero>
  );
};

export default Hero;
