import * as React from "react";

const lineStyle = {
  width: "23px",
  height: "2px",
  backgroundColor: "white",
  position: "absolute",
  left: "33px",
  transition: "all .3s ease-in-out",
  opacity: 1,
};

const lineStyle1 = {
  top: "34px",
};

const lineStyleCross1 = {
  transform: "translate(0px, 8px) rotate(225deg)",
};

const lineStyleCross2 = {
  transform: "rotate(405deg)",
  opacity: 0,
};

const lineStyleCross3 = {
  transform: "translate(0px, -8px) rotate(-225deg)",
};

const lineStyle2 = {
  width: "18px",
  top: "42px",
  left: "35px",
};

const lineStyle3 = {
  top: "50px",
};

const Hamburger = ({ onClick, menuOpen }) => {
  return (
    <div style={{ position: "relative" }} onClick={onClick}>
      <div
        style={{
          ...lineStyle,
          ...lineStyle1,
          ...(menuOpen && lineStyleCross1),
        }}
      ></div>
      <div
        style={{
          ...lineStyle,
          ...lineStyle2,
          ...(menuOpen && lineStyleCross2),
        }}
      ></div>
      <div
        style={{
          ...lineStyle,
          ...lineStyle3,
          ...(menuOpen && lineStyleCross3),
        }}
      ></div>
      <svg width={88} height={85} viewBox="0 0 88 85">
        <title>{"Hamburger"}</title>
        <defs>
          <filter
            x="-65.2%"
            y="-69.8%"
            width="230.4%"
            height="239.5%"
            filterUnits="objectBoundingBox"
            id="prefix__a"
          >
            <feMorphology
              radius={1.5}
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            />
            <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
            <feMorphology radius={1.5} in="SourceAlpha" result="shadowInner" />
            <feOffset in="shadowInner" result="shadowInner" />
            <feComposite
              in="shadowOffsetOuter1"
              in2="shadowInner"
              operator="out"
              result="shadowOffsetOuter1"
            />
            <feGaussianBlur
              stdDeviation={9.5}
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0.0352941176 0 0 0 0 0.721568627 0 0 0 0 1 0 0 0 1 0"
              in="shadowBlurOuter1"
            />
          </filter>
          <path id="prefix__b" d="M6 6h46v43H6z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(15 15)">
            <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
            <use stroke="#09B8FF" strokeWidth={3} xlinkHref="#prefix__b" />
            <use stroke="#FFF" xlinkHref="#prefix__b" />
          </g>
          {/* <g stroke="#FFF" strokeLinecap="square" strokeWidth={2}>
            <path d="M35.714 43.483h16.572M33.643 51.768h21.75M33.643 35.197h21.75" />
          </g> */}
        </g>
      </svg>
    </div>
  );
};

export default Hamburger;
