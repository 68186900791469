import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";

export const StyledBackgroundMiddle = styled(BackgroundImage)`
  height: calc(100% - 72vw);
  width: 100%;
  background-size: 100%;
  background-position: 50% 0;
  position: absolute !important;
  top: 72vw;
  left: 0;
  z-index: -2;
  background-repeat: repeat !important;
`;

export const StyledBackgroundTop = styled(BackgroundImage)`
  width: 100%;
  padding-bottom: 63%;
  background-size: cover;
  background-position: 50% 0;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: -2;
`;

export const StyledBackgroundBottom = styled(BackgroundImage)`
  width: 100%;

  background-size: cover;
  background-position: 50% 0;
  position: absolute !important;
  bottom: 0;
  left: 0;
  z-index: -2;
  height: 1160px;
  @media only screen and (max-width: 768px) {
    background-size: 100% auto !important;
    background-position: 50% 50% !important;
    background-color: #06002d;
  }
`;

export const PageWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  padding: 0 40px;
  box-sizing: border-box;
  @media only screen and (max-width: 768px) {
    padding: 0 15px;
  }
`;

export const Logo = styled.img`
  position: absolute;
  margin: 46px 54px;
  left: 0px;
  top: 0;
  font-size: 36px;
  font-weight: 200;
  letter-spacing: 1px;
  z-index: 50;
  height: 20px;
  @media only screen and (max-width: 768px) {
    margin: 24px 20px;
    height: 16px;
  }
`;

export const Hamburger = styled.div`
  position: absolute;
  right: 0;
  padding: 22px;
  width: auto;
  cursor: pointer;
  z-index: 50;

  @media only screen and (max-width: 768px) {
    transform: translate(10px, -10px) scale(0.7);
    padding: 0;
  }
`;

export const FadedMoon1 = styled.img`
  position: absolute;
  right: 140px;
  top: 110px;
  width: 172px;
  opacity: 0.45;

  @media only screen and (max-width: 768px) {
    right: 5%;
    width: 100px;
  }
`;

export const FadedSaturn = styled.img`
  position: absolute;
  left: 146px;
  top: 354px;
  width: 172px;
  opacity: 0.2;

  @media only screen and (max-width: 768px) {
    left: 6%;
    width: 100px;
  }
`;
