import styled from "styled-components";

export const Hero = styled.div`
  overflow-x: hidden;
  padding: ${({ smaller }) =>
    smaller ? "200px 40px 140px" : "256px 40px 320px"};

  @media only screen and (max-width: 768px) {
    padding: ${({ smaller }) =>
      smaller ? " 186px 10px 90px" : "186px 10px 180px"};
  }

  h1 {
    max-width: 760px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    display: block;
  }
  p {
    text-align: center;
    max-width: 600px;
    margin: 10px auto 0;
  }
`;

export const PinkRectangle = styled.img`
  width: 521px;
  transform: translate(-50%, -50%);
  z-index: -1;
  position: absolute;
  left: 50%;
  top: 50%;
  @media only screen and (max-width: 768px) {
    transform: translate(-50%, -50%) scale(0.7, 0.8);
  }
`;

export const Mouse = styled.img`
  width: 18px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 266px);
  z-index: -1;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
