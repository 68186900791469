/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import * as S from "./Layout.styles";
import Menu from "../Menu/Menu";
import useSiteMetadata from "../SiteMetadata";
import { withPrefix } from "gatsby";
import Footer from "../Footer/Footer";
import Hamburger from "../Hamburger/Hamburger";
import { Helmet } from "react-helmet";

const Layout = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const { title, description } = useSiteMetadata();
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      backgroundTop: file(relativePath: { eq: "background-top.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      backgroundMiddle: file(relativePath: { eq: "background-middle.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      backgroundBottom: file(relativePath: { eq: "background-bottom.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (showMenu) {
      document.body.style.position = "fixed";
    } else {
      document.body.style.position = "relative";
    }
  }, [showMenu]);

  return (
    <div>
      <S.StyledBackgroundMiddle
        fluid={data.backgroundMiddle.childImageSharp.fluid}
      ></S.StyledBackgroundMiddle>
      <S.StyledBackgroundTop
        fluid={data.backgroundTop.childImageSharp.fluid}
      ></S.StyledBackgroundTop>
      <S.StyledBackgroundBottom
        fluid={data.backgroundBottom.childImageSharp.fluid}
      ></S.StyledBackgroundBottom>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />
        {/* 
        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        /> */}
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={`${withPrefix("/")}img/og.png`} />
      </Helmet>
      <S.PageWrapper>
        <Menu show={showMenu}></Menu>

        <Link to="/">
          <S.Logo src={require("../../images/cosmic-logo.svg")}></S.Logo>
        </Link>

        <S.Hamburger>
          <Hamburger
            onClick={() => {
              setShowMenu(!showMenu);
            }}
            menuOpen={showMenu}
          />
        </S.Hamburger>

        <S.FadedMoon1
          src={require("../../images/faded-moon1.png")}
        ></S.FadedMoon1>
        <S.FadedSaturn
          src={require("../../images/faded-saturn1.png")}
        ></S.FadedSaturn>
        <main>{children}</main>
        <Footer></Footer>
      </S.PageWrapper>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
