import styled from "styled-components";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";

export const StyledBackgroundImage = styled(BackgroundImage)`
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: 50% 0;
`;

export const MenuLink = styled.span`
  opacity: ${(props) => (props.selected ? 1 : 0.7)};
  color: ${(props) => (props.selected ? "white" : "auto")};
`;

export const MenuWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background-color: #06002d;
  background-size: 100%;
  background-position: center top;
  overflow: none;
`;

export const MenuColumns = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  text-align: left;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
`;

export const MenuColumn = styled.div`
  width: 50%;
  h1 {
    text-align: left;
    display: flex;
    padding-bottom: 15px;
    :hover {
      color: #c82ffc;
      span {
        opacity: 1 !important;
      }
    }
  }
`;

export const Number = styled.span`
  font-family: Heebo Medium;
  color: #00b2ff;
  font-size: 20px;
  font-weight: bold;
  padding-right: 30px;
`;

export const Alien = styled(Img)`
  max-width: 530px;
  width: 100%;
  height: auto;
`;
