import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";

export const Footer = styled.div`
  padding-top: 60px;
  padding-left: 16px;
`;

export const StyledBackgroundImage = styled(BackgroundImage)`
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: 50% 0;
`;

export const FooterLinks = styled.div`
  display: flex;
  max-width: 720px;
  padding-bottom: 60px;
  p {
    font-size: 16px;
    color: #9d9fb1;
    padding-bottom: 12px;
  }
  @media only screen and (max-width: 768px) {
    display: block;
    padding-bottom: 15px;
  }
`;

export const FooterColumn = styled.div`
  width: 33.333333333%;
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-bottom: 20px;
  }
`;

export const Copyright = styled.div`
  text-align: right;
  font-size: 14px;
  color: #534f6e;
  padding-bottom: 30px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    padding-bottom: 20px;
  }
`;

export const SVGImage = styled.img`
  width: 100%;
  max-width: 24px;
  opacity: 0.6;
  padding-right: 5px;
`;

export const SocialLink = styled.a`
  display: flex;
  font-size: 16px;
  color: #9d9fb1;
  align-items: center;
  padding-bottom: 12px;
  cursor: pointer;
  &:hover {
    color: white;
    img {
      opacity: 1;
    }
  }
`;
