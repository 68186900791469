import React from "react";
import * as S from "./Menu.styles";
import { useStaticQuery, graphql, Link } from "gatsby";
import { CSSTransitionGroup } from "react-transition-group";

const menuData = [
  {
    title: "HOME",
    link: "/",
  },
  {
    title: "SERVICES",
    link: "/services",
  },
  {
    title: "BLOG",
    link: "/blog",
  },
  {
    title: "CONTACT",
    link: "/contact",
  },
];

const Menu = ({ show, location }) => {
  const data = useStaticQuery(graphql`
    query {
      alien: file(relativePath: { eq: "alien.png" }) {
        childImageSharp {
          fluid(maxWidth: 530, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      background: file(relativePath: { eq: "background-top.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <CSSTransitionGroup
      transitionName="example"
      transitionEnterTimeout={500}
      transitionLeaveTimeout={300}
    >
      {show && (
        <S.MenuWrapper key="menu">
          <S.StyledBackgroundImage
            fluid={data.background.childImageSharp.fluid}
          >
            <S.MenuColumns>
              <S.MenuColumn>
                {menuData.map((m, index) => (
                  <Link to={m.link}>
                    <h1>
                      <S.Number>0{index + 1}</S.Number>
                      <S.MenuLink
                        selected={m.link === window.location.pathname}
                      >
                        {m.title}
                      </S.MenuLink>
                    </h1>
                  </Link>
                ))}
              </S.MenuColumn>
              <S.MenuColumn>
                <S.Alien fluid={data.alien.childImageSharp.fluid} alt="alien" />
              </S.MenuColumn>
            </S.MenuColumns>
          </S.StyledBackgroundImage>
        </S.MenuWrapper>
      )}
    </CSSTransitionGroup>
  );
};

export default Menu;
