import React from "react";
import * as S from "./Footer.styles";
import { useStaticQuery, graphql } from "gatsby";

const Footer = () => {
  return (
    <S.Footer>
      <S.FooterLinks>
        <S.FooterColumn>
          <h6>Contact</h6>
          <S.SocialLink href="mailto:hello@wearecosmic.co.uk" target="_blank">
            hello@wearecosmic.co.uk
          </S.SocialLink>

          <S.SocialLink href="tel:0044‪2077709955‬">
            <S.SVGImage src={require("../../images/social/phone.png")} />
            +44‪ (0)20 7770 9955‬
          </S.SocialLink>
          <S.SocialLink
            target="_blank"
            href="https://www.instagram.com/wearecosmicuk"
          >
            <S.SVGImage src={require("../../images/social/instagram.svg")} />
            @wearecosmicuk
          </S.SocialLink>
          <S.SocialLink
            target="_blank"
            href="https://www.facebook.com/wearecosmicuk"
          >
            <S.SVGImage
              src={require("../../images/social/facebook.svg")}
              style={{ transform: "scale(0.9)" }}
            />
            @wearecosmicuk
          </S.SocialLink>
          <S.SocialLink
            target="_blank"
            href="https://twitter.com/wearecosmicuk"
          >
            <S.SVGImage src={require("../../images/social/twitter.svg")} />
            /wearecosmicuk
          </S.SocialLink>
          <S.SocialLink
            target="_blank"
            href="https://www.linkedin.com/company/wearecosmic"
          >
            <S.SVGImage src={require("../../images/social/linkedin.svg")} />
            /wearecosmicuk
          </S.SocialLink>
        </S.FooterColumn>
        <S.FooterColumn>
          <h6>Location</h6>
          <p>London</p>
        </S.FooterColumn>
        <S.FooterColumn>
          <h6>Check out our podcast</h6>
          <p>Coming soon</p>
        </S.FooterColumn>
      </S.FooterLinks>
      <S.Copyright>&copy;wearecosmic {new Date().getFullYear()}</S.Copyright>
    </S.Footer>
  );
};

export default Footer;
